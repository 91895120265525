import { Component } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'src/environments/environment';
import { Angulartics2 } from 'angulartics2';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bccrisponde';

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    angulartics2: Angulartics2
  ) {
    angulartics2.settings.developerMode = !environment.enableAnalytics;
    angulartics2GoogleAnalytics.startTracking();
  }

}
