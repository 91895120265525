import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestComponent } from './components/request/request.component';

import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { Angulartics2Module } from 'angulartics2';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SuccessPageComponent } from './components/success-page/success-page.component';
import { CustomInterceptor } from './helpers/custom-interceptor';
import { DatePipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
// the second parameter 'fr' is optional
registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    RequestComponent,
    SuccessPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    Angulartics2Module.forRoot()
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LfguLoZAAAAAG5gTqip0GP1mlCz4esFv0Ikj_FD' } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'it',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
