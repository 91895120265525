<div class="container contact">
    <div class="row">
        <div class="col-md-4 left-col bg-primary text-white">
            <div class="contact-info text-center ">
                <img src="assets/LOGO_BCC_MILANO_BIANCO_RGB.svg" alt="image" />
                <h2>BCC Risponde</h2>
                <h5>Gentile Cliente, ti invitiamo a compilare il seguente form specificando la filiale di riferimento.
                    <br><br>Sarai ricontattato al pi&ugrave; presto.</h5>
            </div>
        </div>
        <div class="col-md-8 right-col bg-white border border-primary">
            <form #RequestForm="ngForm" novalidate>
                <div class="contact-form">
                    <div class="form-group col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" required [(ngModel)]="request.privacy"
                                #privacy="ngModel" name="privacy"
                                [ngClass]="{'is-invalid': !privacy.valid && (privacy.dirty || privacy.touched || isSubmited) }">
                            <label class="form-check-label" for="exampleCheck1">Consenso ai dati personali</label>
                        </div>
                        <label *ngIf="!privacy.valid && (privacy.dirty || privacy.touched || isSubmited)"
                            class="invalid-feedback d-block">Campo
                            obbligatorio</label>
                        <p> Autorizzo il trattamento dei dati comunicati nella seguente form. I dati inseriti non
                            saranno comunicati a terzi e saranno trattati esclusivamente da Bcc
                            Milano nel rispetto del Regolamento europeo 2016/679 (GDPR).<br>
                            Per informazioni sulla normativa ed i soggetti che utilizzeranno i dati inviati consultare
                            l'informativa alla sezione <a class="font-italic"
                                href="https://www.bccmilano.it/privacy.asp">privacy</a>
                            dedicata.</p>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="name">Nome:</label>
                        <input type="text" class="form-control" placeholder="Nome" name="name" required
                            [(ngModel)]="request.name" #name="ngModel"
                            [ngClass]="{'is-invalid': !name.valid && (name.dirty || name.touched || isSubmited)}">
                        <label *ngIf="!name.valid && (name.dirty || name.touched || isSubmited)"
                            class="invalid-feedback">Campo
                            obbligatorio</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="lastname">Cognome:</label>
                        <input type="text" class="form-control" placeholder="Cognome" name="lastname" required
                            [(ngModel)]="request.lastname" #lastname="ngModel"
                            [ngClass]="{'is-invalid': !lastname.valid && (lastname.dirty || lastname.touched || isSubmited) }">
                        <label *ngIf="!lastname.valid && (lastname.dirty || lastname.touched || isSubmited)"
                            class="invalid-feedback">Campo
                            obbligatorio</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label">Sei già cliente BCC?</label><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="alreadyClient" value="true" required
                                [(ngModel)]="request.cliente" #alreadyClient="ngModel"
                                [ngClass]="{'is-invalid': !alreadyClient.valid && (alreadyClient.dirty || alreadyClient.touched || isSubmited) }">
                            <label class="form-check-label" for="inlineRadio1">Si</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="alreadyClient" value="false"
                                [(ngModel)]="request.cliente" #alreadyClient="ngModel"
                                [ngClass]="{'is-invalid': !alreadyClient.valid && (alreadyClient.dirty || alreadyClient.touched || isSubmited) }">
                            <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                        <label
                            *ngIf="!alreadyClient.valid && (alreadyClient.dirty || alreadyClient.touched || isSubmited)"
                            class="invalid-feedback">Campo
                            obbligatorio</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="phone">Telefono:</label>
                        <input type="text" class="form-control" placeholder="Numero di telefono" name="phone"
                            [required]="request.email === '' || request.email === undefined || request.email === null"
                            [(ngModel)]="request.phone" #phone="ngModel" pattern="^(\(?\+?[0-9]*\)?)?[0-9\- \(\)]*$"
                            [ngClass]="{'is-invalid': !phone.valid && (phone.dirty || phone.touched || isSubmited) }">
                        <label
                            *ngIf="!phone.valid && phone.errors?.required && (phone.dirty || phone.touched || isSubmited)"
                            class="invalid-feedback">Inserisci almeno il numero di telefono o l'indirizzo email</label>
                        <label
                            *ngIf="!phone.valid && phone.errors?.pattern && (phone.dirty || phone.touched || isSubmited) "
                            class="invalid-feedback">Numero di telefono non valido</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="email">Email:</label>
                        <input type="email" class="form-control" placeholder="Indirizzo email" name="email"
                            [(ngModel)]="request.email" #email="ngModel"
                            [required]="request.phone === '' || request.phone === undefined || request.phone === null"
                            pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                            [ngClass]="{'is-invalid': !email.valid && (email.dirty || email.touched || isSubmited) }">
                        <label
                            *ngIf="!email.valid && email.errors?.required && (email.dirty || email.touched || isSubmited)"
                            class="invalid-feedback">Inserisci almeno il numero di telefono o l'indirizzo email</label>
                        <label
                            *ngIf="!email.valid && email.errors?.pattern && (email.dirty || email.touched || isSubmited) "
                            class="invalid-feedback">Indirizzo email non valido</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="filiale">Filiale:</label>
                        <select class="form-control" required [(ngModel)]="request.branch" name="filiale"
                            #filiale="ngModel"
                            [ngClass]="{'is-invalid': !filiale.valid && (filiale.dirty || filiale.touched || isSubmited) }">
                            <option [ngValue]="undefined" disabled selected>
                                Seleziona una filiale
                            </option>
                            <option [value]="item.id" *ngFor="let item of filiali">{{item.description}}</option>
                        </select>
                        <label *ngIf="!filiale.valid && (filiale.dirty || filiale.touched || isSubmited)"
                            class="invalid-feedback">Campo
                            obbligatorio</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="category">Ho bisogno di informazioni/assistenza per:</label>
                        <select class="form-control" required [(ngModel)]="request.category" name="category"
                            #category="ngModel"
                            [ngClass]="{'is-invalid': !category.valid && (category.dirty || category.touched || isSubmited) }">
                            <option [ngValue]="undefined" disabled selected>
                                Seleziona una categoria
                            </option>
                            <option [value]="item.id" *ngFor="let item of categories">{{item.description}}</option>
                        </select>
                        <label *ngIf="!category.valid && (category.dirty || category.touched || isSubmited)"
                            class="invalid-feedback">Campo
                            obbligatorio</label>
                    </div>
                    <div class="form-group col-lg-10">
                        <label class="control-label" for="comment">Note:</label>
                        <textarea class="form-control" rows="5" [(ngModel)]="request.notes" #note="ngModel"
                            name="comment"></textarea>
                    </div>
                    <div class="form-group col-lg-10">
                        <re-captcha [(ngModel)]="request.captcha" name="captcha" required #captchaControl="ngModel"
                            (resolved)="resolved($event)">
                        </re-captcha>
                        <!-- <label [hidden]="captchaControl.valid || captchaControl.pristine" class="invalid-feedback d-block">Captcha
                            must be
                            solved</label> -->
                        <!-- <label [hidden]="!captchaControl.valid" class="success">Captcha is valid</label> -->
                        <label
                            *ngIf="!captchaControl.valid && (captchaControl.dirty || captchaControl.touched || isSubmited)"
                            class="invalid-feedback d-block">Campo obbligatorio</label>
                        <!-- <label [hidden]="!RequestForm.form.valid" class="success">The form is valid</label> -->
                    </div>
                    <div class="form-group col-lg-10 pt-3 text-center">
                        <button type="submit" class="btn btn-lg btn-secondary" (click)="submitRequest(RequestForm)"
                                angulartics2On="click" angularticsAction="Invio richiesta"
                                angularticsCategory="Richiesta">Invia richiesta</button>
                        <!-- <div class="col-sm-offset-2 col-sm-10 text-center">
                            
                        </div> -->
                    </div>
                    <p class="text-muted font-weight-light pt-3">
                        ATTENZIONE! LE RICHIESTE INERENTI L'HOME BANKING INOLTRATE TRAMITE QUESTA FORM NON
                        RICEVERANNO RISPOSTA.<br>
                        Questa form non è destinata all'assistenza tecnica per i problemi relativi al servizio Relax
                        Banking. Qualora aveste delle difficoltà nell'uso della banca elettronica (password
                        smarrite, utente bloccato, difficoltà di accesso o altro) dovrete rivolgervi al Call Center
                        di assistenza telefonica al numero verde 800 42 42 42 o alla vostra Filiale.<br>
                        <br>

                    </p>
                </div>

            </form>

        </div>
    </div>
</div>