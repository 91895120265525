import { Injectable } from '@angular/core';
import { Filiale } from '../models/filiali';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { HttpStandard } from '../models/httpstandard';
import { Category } from '../models/category';
import { UserRequest } from '../models/request';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private logUrl = environment.endPoint;
  constructor(
    private http: HttpClient
  ) { }

  getFiliali(): Observable<Filiale[]> {
    const completeUrl = this.logUrl + '/branch';
    return this.http.get(completeUrl)
      .pipe(
        map(response => {
          return HttpStandard.extractData(response);
        }),
        catchError(error => {
          return HttpStandard.handleError(error);
        })
      );
  }

  getCategories(): Observable<Category[]> {
    const completeUrl = this.logUrl + '/category';
    return this.http.get(completeUrl)
      .pipe(
        map(response => {
          return HttpStandard.extractData(response);
        }),
        catchError(error => {
          return HttpStandard.handleError(error);
        })
      );
  }

  verifyCaptcha(data: string): Observable<any> {
    const completeUrl = this.logUrl + '/userrequest/captcha';

    return this.http.post(completeUrl, data)
      .pipe(
        map(response => {
          return HttpStandard.extractData(response);
        }),
        catchError(error => {
          return HttpStandard.handleError(error);
        })
      );
  }

  sendRequest(data: UserRequest): Observable<any> {
    const completeUrl = this.logUrl + '/userrequest';

    return this.http.post(completeUrl, data)
      .pipe(
        map(response => {
          return HttpStandard.extractData(response);
        }),
        catchError(error => {
          return HttpStandard.handleError(error);
        })
      );
  }

}
