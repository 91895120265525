import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequestComponent } from './components/request/request.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';


const routes: Routes = [
  {
    path: '',
    component: RequestComponent
  },
  {
    path: 'esito',
    component: SuccessPageComponent
  },
  {
    path: 'backoffice',
    loadChildren: () => import('../app/components/backoffice/backoffice.module').then(m => m.BackofficeModule)
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
