import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { UserRequest } from '../../models/request';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { Filiale } from 'src/app/models/filiali';
import { Category } from 'src/app/models/category';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  request: UserRequest;
  isSubmited = false;
  filiali: Filiale[];
  categories: Category[];

  constructor(
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private router: Router
  ) {
    this.request = new UserRequest();
    this.request.id = 0;
    this.filiali = [];
    this.categories = [];
  }

  ngOnInit(): void {
    this.getFiliali();
    this.getCategories();
  }

  getFiliali() {
    this.spinner.show();
    this.dataService.getFiliali().subscribe(
      result => {
        this.filiali = result;
        this.filiali.sort(function (a, b) {
          if (a.description < b.description) { return -1; }
          if (a.description > b.description) { return 1; }
          return 0;
        });
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        Swal.fire({
          title: 'Errore',
          text: 'Si è verificato un errore imprevisto. Riprovare più tardi',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );
  }

  getCategories() {
    this.spinner.show();
    this.dataService.getCategories().subscribe(
      result => {
        this.categories = result;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        Swal.fire({
          title: 'Errore',
          text: 'Si è verificato un errore imprevisto. Riprovare più tardi',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );
  }

  submitRequest(form: any) {
    if (!form.valid) {
      this.isSubmited = true;
      Swal.fire({
        title: 'Attenzione',
        text: 'Campi mancanti',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }

    this.dataService.sendRequest(this.request).subscribe(
      result => {
        this.spinner.hide();
        this.router.navigate(['esito']);
      },
      error => {
        this.spinner.hide();
        let message = '';
        switch (error.status) {
          case 422:
            // input non valido
            message = 'Dati non validi.';
            break;
          case 404:
            // filiale o categoria non trovati
            message = 'Filiale o categoria non validi';
            break;
          case 412:
            // univocità richiesta
            message = 'Richiesta non valida';
            break;
          default:
            message = 'Si è verificato un errore imprevisto. Riprovare più tardi';
            break;
        }

        Swal.fire({
          title: 'Errore',
          text: message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );

  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);

    if (captchaResponse === null) {
      console.log('captcha null');
    } else {
      this.spinner.show();
      this.dataService.verifyCaptcha(captchaResponse).subscribe(
        result => {
          console.log(result);
          this.spinner.hide();
          if (result.esito !== 'OK') {
            Swal.fire({
              title: 'Errore',
              text: 'Si è verificato un errore durante la verifica del reCAPTCHA. Si prega di riprovare.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        },
        error => {
          this.spinner.hide();
          Swal.fire({
            title: 'Errore',
            text: 'Si è verificato un errore durante la verifica del reCAPTCHA. Si prega di riprovare.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    }

  }


}
