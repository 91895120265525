export class UserRequest {
    isExpanded: boolean;
    id: number;
    name: string;
    lastname: string;
    cliente: boolean;
    phone: string;
    email: string;
    branch: number;
    category: number;
    notes: number;
    privacy: boolean;
    captcha: any;
}

export class BackofficeRequest extends UserRequest {
    branchDesc: string;
    categoryDesc: string;
    fulfillment: boolean;
    dtReq: string;
    dtFulfillment: string;
}

export class BackofficeRequestResponse {
    listData: BackofficeRequest[];
    downloadFileName: string;
}

export class BranchBackofficeRequest extends BackofficeRequest {
    token: string;
}