
import { Observable } from 'rxjs';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';

export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }
        if (!req.headers.has('Accept')) {
            req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        }
        if (req.url.indexOf('ws') !== -1) {
            if (req.url.indexOf('?') !== -1) {
                req = req.clone({ url: req.url + '&v=' + Date.now() });
            } else {
                req = req.clone({ url: req.url + '?v=' + Date.now() });
            }
        }

        // req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return next.handle(req);

    }
}
