import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class HttpStandard {

  static extractData(res: any) {

    if (res.status < 200 || res.status >= 300) {
      console.error('server error: ' + res.status);
    }
    return res || {};
  }

  static handleError(error: HttpErrorResponse) {
    let errMsg: string;
    let statusError: number;
    let errBody: object;
    // In a real world app, we might use a remote logging infrastructure
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

      if (error.status === 0) {
        errMsg = 'nessuna risposta dal server';
      } else {
        let body = null;
        try {
          body = error || '';
          const err = body.error || JSON.stringify(body);
          errBody = err;
          errMsg = `${error.status} - ${error.error.errorMessage || ''}`;
          statusError = error.status || 999;
        } catch (ex) {
          errMsg = `${error.status} - ${error.statusText || ''}`;
        }
      }
    }
    // return an observable with a user-facing error message
    return throwError({ message: errMsg, status: statusError, errorBody: errBody });

  }
}
