<div class="h-100 d-flex flex-column justify-content-between">
  <div class="c-note-head">
    <div class="l-container-fluid">
      <div class="c-iccrea-logo">
        <div class="tdBan2"><img src="https://www.bccmilano.it/archivio/502/131177.svg" border="0" id="131177"
            alt="Gruppo bancario Iccrea"></div>
      </div>
  
      <div class="c-bcc-logo">
        <div class="tdBan3"><img src="https://www.bccmilano.it/archivio/502/131176.svg" border="0" id="131176"
            alt="BCC Credito Cooperativo"></div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  
  <div class="bg-primary p-3 text-white text-center">
    Bcc Milano - Via De Gasperi, 11 – 20061 - Carugate (MI) - Tel: 0292741 - P.IVA 15240741007 -
    <span class="glyphicon glyphicon-envelope" aria-hidden="true"></span> soci@bccmilano.it
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>