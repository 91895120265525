<div class="container h-100 d-flex justify-content-center align-items-center">
    <div class="card">
        <div class="card-body">
            <div class="text-center py-3 h5">
                Gentile Cliente, grazie per la tua richiesta. <br>
                Sarà nostra cura ricontattarti nel più breve tempo possibile.
            </div>
            <div class="py-4 text-center">
                Team BCC Milano
            </div>
            <div class="text-center">
                <a [routerLink]="['']" class="btn btn-primary" angulartics2On="click"
                    angularticsAction="Nuova richiesta" angularticsCategory="Richiesta">Nuova richiesta</a>
            </div>
        </div>
    </div>


</div>